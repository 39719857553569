import React, { lazy } from 'react';
import {Navigate} from "react-router-dom";
// import ProductPrice from "../../Component/productsprice";
// import ProductionDetails from "../../Component/ProductionDetails";
// import ProductsPriceView from "../../Component/productspriceview";
import ToggleMenu from "../../Component/toggleMenu";


const Home = lazy(() => import("../../Component/Home"));
const Company=lazy(()=>import("../../Component/Company"));
const Users = lazy(() => import("../../Component/Users"));
const AccessSettings=lazy(()=>import("../../Component/AccessSettings"));
const Product = lazy(() => import("../../Component/Product"));
const EditProduct = lazy(() => import("../../Component/EditProduct"));
const SpecificationDetails = lazy(() => import("../../Component/SpecificationDeatils"));
const Specification = lazy(() => import("../../Component/Specification"));
const Tax = lazy(() => import("../../Component/Tax"));
const MobileMenu = lazy(() => import("../../Component/MobileMenu"));
const Category = lazy(() => import("../../Component/Category"));
const Brand = lazy(() => import("../../Component/Brand"));
const Register = lazy(() => import("../../Component/Register"));
const Invoice = lazy(() => import("../../Component/invoice"));
const Login = lazy(() => import("../../Component/Login"));
const NoPage = lazy(() => import("../../Component/NoPage"));
const Subscription = lazy(() => import("../../Component/Subscription"));
const BillingReport = lazy(() => import("../../Component/BillingReport"));
const PaymentStatus = lazy(() => import("../../Component/PaymentStatus"));
const EasebuzzPaymentStatus = lazy(() => import("../../Component/EasebuzzPaymentStatus"));
const ReturnSettings = lazy(() => import("../../Component/ReturnRefund"));
const RefundPaymentReport = lazy(() => import("../../Component/RefundPayment"));
const CustomerSummerySettings = lazy(() => import("../../Component/CustomerSummery"));
const ValidityInformation = lazy(() => import("../../Component/ValidityInformation"));
const Kyc = lazy(() => import("../../Component/Kyc"));
const Support = lazy(() => import("../../Component/Support"));
const Faq = lazy(() => import("../../Component/Faq"));
const ChangePassword = lazy(() => import("../../Component/ChangePassword"));
const Vendor = lazy(() => import("../../Component/Vendor"));
const PrivacyPolicyMenu = lazy(() => import("../../Component/PrivacyPolicyMenu"));
const TermsAndConditionMenu = lazy(() => import("../../Component/TermsAndConditionsMenu"));
const ContactUsMenu = lazy(() => import("../../Component/ContactUsMenu"));

const ProductRate = lazy(() => import("../../Component/ProductRate"));
const StockJournal = lazy(() => import("../../Component/StockJournal"));
const Purchase = lazy(() => import("../../Component/Purchase"));
const Order = lazy(() => import("../../Component/Order"));
const Payment = lazy(() => import("../../Component/Payment"));
// const BillInfo = lazy(() => import("../../Component/BillInfo"));
const Packing = lazy(() => import("../../Component/Packing"));
const Pending = lazy(() => import("../../Component/Pending"));
const Billed = lazy(() => import("../../Component/Billed"));
const Dispatch = lazy(() => import("../../Component/Dispatch"));
const Cancel = lazy(() => import("../../Component/Cancel"));
const Staff = lazy(() => import("../../Component/Staff"));
const Damagetype = lazy(() => import("../../Component/damagetype"));
const DamageReport = lazy(() => import("../../Component/Damagereport"));
const Delivered = lazy(() => import("../../Component/Delivered"));
const Enquiry = lazy(() => import("../../Component/Enquiry"));
const DeliveryCharges = lazy(() => import("../../Component/DeliveryCharges"));
const BuyersCategory = lazy(() => import("../../Component/BuyersCategory"));
const BuyersList = lazy(() => import("../../Component/BuyersList"));
const OfferMaster = lazy(() => import("../../Component/OfferMaster"));
const OfferMapping = lazy(() => import("../../Component/OfferMapping"));
const AddOfferRules = lazy(() => import("../../Component/AddOfferRules"));
const DiscountMaster = lazy(() => import("../../Component/DiscountMaster"));
const DiscountRules = lazy(() => import("../../Component/DiscountRules"));
const AddDiscountRules = lazy(() => import("../../Component/AddDiscountRules"));
const EditDiscountRules = lazy(() => import("../../Component/EditDiscountRules"));
const EditOfferRules = lazy(() => import("../../Component/EditOfferRules"));
const Template = lazy(() => import("../../Component/Template"));
const Pages = lazy(() => import("../../Component/termsandcondition"));
const HomeComponent = lazy(() => import("../../Component/HomeComponent"));

const EditHomeComponent = lazy(() => import("../../Component/EditHomeComponent"));
const Inventory = lazy(() => import("../../Component/Inventory"));
const Productsprice = lazy(() => import("../../Component/productsprice"));
const ProductsPriceView = lazy(() => import("../../Component/productspriceview"));
const ProductionDetails = lazy(() => import("../../Component/ProductionDetails"));
const TermsAndCondition = lazy(() => import("../../Component/termsandcondition"));
const DeliveryCharge = lazy(() => import("../../Component/Deliverycharge"));
const TogggleMenu = lazy(() => import("../../Component/toggleMenu"));
const authrouter = [
  {
    index: true,
    exact: true,
    path: "/",
    element: <Home />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/home-component",
    element: <HomeComponent />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/edit-homecomponent/:id",
    element: <EditHomeComponent />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/templates",
    element: <Template />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/pages",
    element: <Pages />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/company",
    element: <Company />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/user",
    element: <Users />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/accesssettings",
    element: <AccessSettings />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/staff",
    element: <Staff />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/editproduct/:id",
    element: <EditProduct />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/products",
    element: <Product />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/productsprice",
    element: <Productsprice />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/productspriceview/:id",
    element: <ProductsPriceView />,
    navigate: <Navigate to="/productsprice" replace />,
  },
  {
    exact: true,
    path: "/ProductionDetails",
    element: <ProductionDetails />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/specification",
    element: <Specification />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/specification-details",
    element: <SpecificationDetails />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/tax",
    element: <Tax />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/deliverycharge",
    element: <DeliveryCharge />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/togglemenu",
    element: <TogggleMenu />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/category",
    element: <Category />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/brand",
    element: <Brand />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/subscription",
    element: <Subscription />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/billing-report",
    element: <BillingReport />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/payment-report",
    element: <PaymentStatus />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/easebuzzpaymentstatus",
    element: <EasebuzzPaymentStatus />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/return-report",
    element: <ReturnSettings />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/refundpayment-report",
    element: <RefundPaymentReport />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/CustomerSummery-report",
    element: <CustomerSummerySettings />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/validity-information",
    element: <ValidityInformation />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/kyc",
    element: <Kyc />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/support",
    element: <Support />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/faq",
    element: <Faq />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/change-password",
    element: <ChangePassword />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/vendor",
    element: <Vendor />,
    navigate: <Navigate to="/login" replace />,
  },
  // {
  //   exact: true,
  //   path: "/privacypolicymenu",
  //   element: <PrivacyPolicyMenu />,
  //   navigate: <Navigate to="/login" replace />,
  // },
  // {
  //   exact: true,
  //   path: "/termsandconditionmenu",
  //   element: <TermsAndConditionMenu />,
  //   navigate: <Navigate to="/login" replace />,
  // },
  // {
  //   exact: true,
  //   path: "/contactusmenu",
  //   element: <ContactUsMenu />,
  //   navigate: <Navigate to="/login" replace />,
  // },
  {
    exact: true,
    path: "/stock-status",
    element: <Inventory />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/product-rate",
    element: <ProductRate />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/stock-journal",
    element: <StockJournal />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/purchase",
    element: <Purchase />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/order",
    element: <Order />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/payment",
    element: <Payment />,
    navigate: <Navigate to="/login" replace />,
  },
  // {
  //   exact: true,
  //   path: "/BillInfo",
  //   element: <BillInfo />,
  //   navigate: <Navigate to="/login" replace />,
  // },
  {
    exact: true,
    path: "/packing",
    element: <Packing />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/pending",
    element: <Pending />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/billed",
    element: <Billed />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/dispatched",
    element: <Dispatch />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/delivered",
    element: <Delivered />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/cancel",
    element: <Cancel />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/damagetype",
    element: <Damagetype />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/damagereport",
    element: <DamageReport />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/mobilemenu",
    element: <MobileMenu />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/enquiry",
    element: <Enquiry />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/delivery-charge",
    element: <DeliveryCharges />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/buyers-category",
    element: <BuyersCategory />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/buyers",
    element: <BuyersList />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/offer-master",
    element: <OfferMaster />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/offer-rules",
    element: <OfferMapping />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/addoffer-rules",
    element: <AddOfferRules />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/editoffer-rules/:id",
    element: <EditOfferRules />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/discount-master",
    element: <DiscountMaster />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/discount-rules",
    element: <DiscountRules />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/adddiscount-rules",
    element: <AddDiscountRules />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/editdiscount-rules/:id",
    element: <EditDiscountRules />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/terms-and-condition",
    element: <TermsAndCondition />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/invoice",
    element: <Invoice />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: "",
    path: "*",
    element: <NoPage />,
    navigate: "",
  },
];
const commonrouter = [
  {
    exact: true,
    path: "/signup",
    element: <Register />,
    navigate: <Navigate to="/login" replace />
  },
  {
    exact: true,
    path: "/",
    element: <Navigate to="/login" replace />,
    navigate: <Login />
  },
  {
    exact: true,
    path: "/login",
    element: <Login />,
    // navigate: <Navigate to="/" replace />
  },
  {
    exact: '',
    path: "*",
    element: <NoPage />,
    navigate: ''
  },
  {
    exact: true,
    path: "/privacypolicymenu",
    element: <PrivacyPolicyMenu />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/termsandconditionmenu",
    element: <TermsAndConditionMenu />,
    navigate: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: "/contactusmenu",
    element: <ContactUsMenu />,
    navigate: <Navigate to="/login" replace />,
  },
];
export { authrouter, commonrouter };