import React, { useEffect, useState, useRef } from 'react';
import './Assets/Css/style.css';
import Dashboard from './Pages/Dashboard';
import API from './Store/Api/ApiService';
import crud_service from './Store/Api/CrudService';
import { useDispatch } from 'react-redux';
import MyContext from './MyContext';
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';

const user = JSON.parse(localStorage.getItem('persist:root'))?.admin || '';
const userData = user && JSON.parse(user);

const App = () => {
  const [accessSetting, setAccessSetting] = useState();
  const [lastActivity, setLastActivity] = useState(Date.now());
  const idleTimerRef = useRef(null);

  const api = new API();
  const crud = new crud_service();
  const dispatch = useDispatch();

  const checkEveryLogin = async () => {
    await crud.create('verifylogin', '', async (err, res) => {
      if (res?.data?.success === true) {
      } else {
        await api.logout(dispatch);
      }
    });
  };

  const getAccessSetting = async () => {
    await crud.getSingle('accesssettings', userData?.admin?.data?._id, (err, res) => {
      if (res?.data?.success === true) {
        setAccessSetting(res?.data?.data);
      }
    });
  };  

  const onIdle = async() => {
    // console.log("User is idle. Logging out...");
    // Log out the user or take any other action
    await api.logout(dispatch);
    window.location.href="/login"
  };

  useEffect(() => {
    checkEveryLogin();
    getAccessSetting();
  }, []);

  // useEffect(() => {
  //   if (typeof lastActivity !== 'undefined') {
  //     // console.log("User activity detected");
  //   }
  // }, [lastActivity]);

  // useEffect(() => {
  //   // console.log("Idle timeout setup");
  //   return () => {
  //     // console.log("Component will unmount");
  //   };
  // }, []);

  return (
    <React.Fragment>
      <MyContext.Provider value={accessSetting}>
        <IdleTimerProvider ref={idleTimerRef} timeout={20 * 60 * 1000} onIdle={onIdle} debounce={500}>
          <Dashboard userName={userData ? userData : null} />
        </IdleTimerProvider>
      </MyContext.Provider>
    </React.Fragment>
  );
};

export default App;
