import React, { useState, useEffect } from 'react'
import { Switch, Dropdown, Layout, theme, Spin, Space, Avatar, Input, Badge, Modal, Tooltip, message } from 'antd';
import styled from 'styled-components';
import API from '../Store/Api/ApiService';
import crud_service from '../Store/Api/CrudService';
const ToggleMenu = () => {
    const [toggle, setToggle] = useState();
    const crud = new crud_service();
    const api = new API();

    const getToggle = async () => {
        await crud.getSingle('testingdemo', '', async (err, res) => {
          if (res?.data?.success === true) {
            setToggle(res?.data?.data?.value == 1 ? true : false)
          } else {
            console.log("Get Toggle Error");
          }
        });
      }

      useEffect(() => {
        getToggle()
      })

      const onChange = (checked) => { 
        console.log(`switch to ${checked}`);
        let data = {
          value : checked == true ? 1 : 0, 
        }
        crud.update('testingdemo',"", data, (err, res) => {
          // console.log("res===?", res?.data)
          if(res?.data?.success === true){
            message?.success(res?.data?.message)
            setToggle(checked);
          }
          else{
            console.log("onChange Error");
          }
        })
      };

  return (
    <div>Delete Option <Switch name="switch" checked={toggle} onChange={onChange} /></div>
  )
}

export default ToggleMenu